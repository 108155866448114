import { render, staticRenderFns } from "./updateDate.vue?vue&type=template&id=8476d46a&scoped=true&lang=html"
import script from "./updateDate.vue?vue&type=script&lang=js"
export * from "./updateDate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8476d46a",
  null
  
)

export default component.exports