<template lang="html">
    <div class="settingContent">
		<div class="handleBox flex flex-jc-sb flex-ai-c">
	       <h3 style="">{{$route.name}}</h3>
	        <div class="handlebox flex flex-ai-c">
	            <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="edit()">新建</el-button>
	        </div>
		</div>
        <div class="contentBox" style="width:100%;padding:0 40px">
			<el-table :data="list" size="medium" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" :max-height="tableHeight">
                <el-table-column prop="version" label="版本"></el-table-column>
				<el-table-column prop="content" label="内容"></el-table-column>
                <el-table-column prop="create_time" label="日期"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
						<el-button-group>
							<el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
							<el-button size="mini" type="primary" plain @click="del(scope.row.id)">删除</el-button>
						</el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <win :title="title" v-if="winIsShow" @winClose="winIsShow=false" @winEnter="submit">
            <el-form ref="edit" :rules="rules" :model="formData" label-width="80px">
                <el-form-item label="版本" prop="version">
                    <el-input placeholder="请输入版本" v-model="formData.version"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input placeholder="请输入内容" v-model="formData.content"></el-input>
                </el-form-item>
            </el-form>
        </win>
    </div>
</template>

<script>
import win from "../../components/win.vue";
export default {
    components: {
        win
    },
    data: function() {
        return {
            search: {
                searchValue: '', //搜索值
            },
            value: {
                name: ''
            },
            title: '添加版本',
            winIsShow: false,
            formData: {
                money: ''
            },
            tableHeight: 660,
            // 表单验证
            rules: {
                version: [{
                    required: true,
                    message: '请输入版本',
                    trigger: 'change'
                }],
                content: [{
                    required: true,
                    message: '请输入内容',
                    trigger: 'change'
                }]
            },
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init: function() {
            // this.autoHeight();
            this.getList();
        },
        //自动高度
        autoHeight: function() {
            var duoyu = 60 + 70 + 61 + 30 + 65 + 15 + 40;
            this.tableHeight = document.documentElement.clientHeight - duoyu;
            window.onresize = () => {
                this.tableHeight = document.documentElement.clientHeight - duoyu;
            }
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        //添加或修改
        edit: function(detail = false) {
            if (detail) {
                this.formData = JSON.parse(JSON.stringify(detail));
                this.title = '编辑版本';
            } else {
                this.formData = {};
                this.title = '添加版本';
            }
            this.winIsShow = true;
        },
        // 获取列表
        getList() {
            this.http.post('/admin.sysLog/index', {
                curr: this.curr,
                row: this.row,
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
            });
        },
        // 删除
        del: function(id, name) {
            this.$confirm('确定删除吗?', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var apiUrl = "/admin.sysLog/delete";
                this.http.post(apiUrl, {
                    id
                }).then(res => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 提交
        submit() {
            // 验证
            this.$refs['edit'].validate((valid) => {
                if (valid) {
                    var apiUrl = "/admin.sysLog/save";
                    this.http.post(apiUrl, this.formData).then(res => {
                        this.winIsShow = false;
                        this.$message({
                            message: '提交成功',
                            type: 'success'
                        });
                        this.getList();
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="css" scoped>
</style>
